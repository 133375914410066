@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
    font-family: "Urbanist", sans-serif;
}

html,
body,
#root {
    min-height: 100%;
}

html,
.bg-fullscreen {
    background-color: #020e07;
    background-image: url(./images/dollar-background.png);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
text,
a,
pre,
strong,
label {
    @apply text-primary;
}

pre {
    white-space: pre-wrap;
    font-family: "Urbanist", sans-serif;
}

h2 {
    @apply text-xl font-bold;
}

h3 {
    @apply text-lg sm:text-xl font-bold;
}

h4 {
    @apply text-lg font-bold;
}

h5 {
    @apply text-base font-semibold;
}

a {
    color: inherit;
}

.text-gradient-primary {
    background: -webkit-linear-gradient(45deg, #1cf683, #16ca6a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-gold {
    background: -webkit-linear-gradient(
        0deg,
        #f2dc49,
        #eedd6f,
        #f2dc49,
        #eeda5a
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-shadow {
    text-shadow: 0 2px 8px #000000;
}

ul {
    margin-top: 8px;
    margin-bottom: 8px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #0d0d0d;
}

input[type="checkbox"] {
    accent-color: #1cf683;
}

meter.neutral-meter {
    --background: rgba(255, 255, 255, 0.1);
    --optimum: rgba(255, 255, 255, 0.9);
    --sub-optimum: rgba(255, 255, 255, 0.9);
    --sub-sub-optimum: rgba(255, 255, 255, 0.9);

    height: 14px;
    display: block;
}

/* The gray background in Chrome, etc. */
meter.neutral-meter::-webkit-meter-bar {
    background: var(--background);
    border: none;
    transition: 1s width;
}

/* The green (optimum) bar in Firefox */
meter.neutral-meter:-moz-meter-optimum::-moz-meter-bar {
    background: var(--optimum);
    transition: 1s width;
}

/* The green (optimum) bar in Chrome etc. */
meter.neutral-meter::-webkit-meter-optimum-value {
    background: var(--optimum);
    transition: 1s width;
}

/* The yellow (sub-optimum) bar in Firefox */
meter.neutral-meter:-moz-meter-sub-optimum::-moz-meter-bar {
    background: var(--sub-optimum);
    transition: 1s width;
}

/* The yellow (sub-optimum) bar in Chrome etc. */
meter.neutral-meter::-webkit-meter-suboptimum-value {
    background: var(--sub-optimum);
    transition: 1s width;
}

/* The red (even less good) bar in Firefox */
meter.neutral-meter:-moz-meter-sub-sub-optimum::-moz-meter-bar {
    background: var(--sub-sub-optimum);
    transition: 1s width;
}

/* The red (even less good) bar in Chrome etc. */
meter.neutral-meter::-webkit-meter-even-less-good-value {
    background: var(--sub-sub-optimum);
    transition: 1s width;
}

/* Limit meter */

meter.limit-meter {
    --background: rgb(28 246 131);
    --optimum: rgba(255, 255, 255, 1);
    --sub-optimum: rgba(255, 255, 255, 0.9);
    --sub-sub-optimum: rgba(255, 255, 255, 0.9);

    height: 14px;
    display: block;
}

meter.limit-meter::-webkit-meter-bar {
    background: var(--background);
    border: none;
    transition: 1s width;
}

/* The green (optimum) bar in Firefox */
meter.limit-meter:-moz-meter-optimum::-moz-meter-bar {
    background: var(--optimum);
    transition: 1s width;
}

/* The green (optimum) bar in Chrome etc. */
meter.limit-meter::-webkit-meter-optimum-value {
    background: var(--optimum);
    transition: 1s width;
}

/* The yellow (sub-optimum) bar in Firefox */
meter.limit-meter:-moz-meter-sub-optimum::-moz-meter-bar {
    background: var(--sub-optimum);
    transition: 1s width;
}

/* The yellow (sub-optimum) bar in Chrome etc. */
meter.limit-meter::-webkit-meter-suboptimum-value {
    background: var(--sub-optimum);
    transition: 1s width;
}

/* The red (even less good) bar in Firefox */
meter.limit-meter:-moz-meter-sub-sub-optimum::-moz-meter-bar {
    background: var(--sub-sub-optimum);
    transition: 1s width;
}

/* The red (even less good) bar in Chrome etc. */
meter.limit-meter::-webkit-meter-even-less-good-value {
    background: var(--sub-sub-optimum);
    transition: 1s width;
}

.gradient-mask-y {
    mask-image: linear-gradient(
        rgba(255, 255, 255, 0.3),
        #000,
        rgba(255, 255, 255, 0.3)
    );
}

.gradient-mask-x {
    mask-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.1) 10%,
        #000,
        rgba(255, 255, 255, 0.1) 90%
    );
}

.circular-progress {
    --size: 250px;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 20px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * pi * 2);
    --dash: calc((var(--progress) * var(--circumference)) / 100);
    animation: progress-animation 5s linear 0s 1 forwards;
}

.circular-progress circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
}

.circular-progress circle.circle-progress-bg {
    stroke: #ddd;
}

.circular-progress circle.circle-progress-fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 0.3s linear 0s;
    stroke: #5394fd;
}

.bg-color-mix-gradient {
    background-image: radial-gradient(
        circle at center,
        #f2dc49 30%,
        #1ca6f6 20%,
        #1cf683 20%,
        #16ca6a 20%,
        rgba(255, 255, 255, 0) 100%
    );
}

.text-wrapper h2 {
    @apply mb-4;
}

.text-wrapper p {
    @apply mb-4;
}

.text-wrapper div {
    @apply mb-4;
}

.text-wrapper span,
.text-wrapper div,
strong,
.text-wrapper p {
    @apply text-secondary;
}

ul {
    list-style-type: disc;
    padding-left: 16px;
    margin-bottom: 16px;
}

.w-md-editor-bar {
    display: none !important;
}

.w-md-editor {
    box-shadow: none !important;
    --color-prettylights-syntax-constant: #1cf683 !important;
    --color-accent-fg: #1cf683 !important;
    --color-danger-fg: #1cf683 !important;
}

.wmde-markdown-color,
textarea.w-md-editor-text-input,
.w-md-editor-text-pre .code-line,
.w-md-editor-text-pre .code-line .token,
.w-md-editor-text {
    font-family: "Urbanist", sans-serif !important;
    font-size: 16px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    font-style: normal !important;
}

.w-md-editor-text-pre .code-line {
    display: block;
}

.w-md-editor-toolbar {
    background: transparent !important;
    padding: 3px !important;
    border: none !important;
}

.w-md-editor-content,
.w-md-editor {
    height: auto !important;
}

.w-md-editor-text {
    min-height: 44px !important;
}

.wmde-markdown {
    display: flex;
    flex-direction: column;
}

.wmde-markdown p {
    @apply text-secondary;
    margin: 0 0 16px 0 !important;
}

.wmde-markdown p:last-child {
    @apply text-secondary;
    margin: 0 0 0 0 !important;
}

.wmde-markdown hr {
    background-color: rgba(255, 255, 255, 0.1) !important;
    height: 1px !important;
    border: 0 !important;
    margin: 16px 0 !important;
}

.wmde-markdown blockquote {
    border-left: 2px solid rgba(255, 255, 255, 0.1) !important;
    margin: 0 !important;
    padding: 0 16px !important;
}

.wmde-markdown ul {
    padding-left: 16px !important;
    margin: 16px 0 !important;
    display: flex;
    flex-direction: column;
}

.wmde-markdown ol {
    padding-left: 16px !important;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    list-style: decimal;
}

.wmde-markdown blockquote {
    display: block;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    margin-inline-start: 0 !important;
    margin-inline-end: 40px;
}

@keyframes logo-loader-animation {
    0% {
        transform: translateY(-50%);
    }

    100% {
        transform: translateY(-5%);
    }
}
